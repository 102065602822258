<template> 
	<div class="accordion" role="tablist"> 
    <div v-for="(row, key, index) in wallets">         
    <div v-for="(value, idx) of row"> 
      <b-card no-body class="mb-1" v-if="value.show">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle="'accordion' + key" :style="'background-color:' + value.color" style="text-align:left"> {{ value.group }} | {{ value.currency }} ( {{ value.tab }} ) </b-button>
        </b-card-header>
        <b-collapse :id="'accordion' + key" visible accordion="my-accordion" role="tabpanel">
          <b-card-body>

            <div class="row justify-content-end">
                <div class="col-3">
                    <b-button-group size="sm">
                    <div v-for="(val, ky, idx2) in value.button">   
                      <b-button variant="info" v-on:click="actionChange(key,ky)">{{ val }}</b-button>            
                    </div>
                    </b-button-group>
                </div>
            </div>
          
            <b-row>                

                <b-col sm="4">
                    <div class="form-group">
                        <label for="input-small" >Wallet Name :</label>                        
                        <b-form-input id="input-small" v-model="value.group" :readonly="true"></b-form-input>
                    </div>
                </b-col>

                <b-col sm="4">
                    <div class="form-group">
                        <label for="input-small" >Currency :</label>                        
                        <b-form-input id="input-small" v-model="value.currency" :readonly="true"></b-form-input>
                    </div>
                </b-col>

                <b-col sm="4">
                    <div class="form-group">
                        <label for="input-small" >Balance :</label>                        
                        <b-form-input id="input-small" v-model="value.balance" :readonly="true"></b-form-input>
                    </div>
                </b-col>
            </b-row>

            <b-row>
                <b-col sm="12">
                    <div class="form-group">
                        <label for="input-small" >Store Ref. :</label>                        
                        <b-form-input id="input-small" v-model="value.storeRef" :readonly="true"></b-form-input>
                    </div>
                </b-col>
            </b-row>

            <CTabs v-if="value.uniqueStr">            
                <CTab title="Send" active><br/> 
                                <b-row>
                                   <b-col sm="4">
                                      <div class="form-group">
                                          <label for="input-small" >Topup Daily Limit :</label>
                                          <span v-if="def">
                                          <b-form-input id="input-small" v-model="value.default.send.studl" :readonly="true"></b-form-input>
                                          </span>
                                          <span v-else>
                                          <b-form-input id="input-small" v-model="value.limits.send.studl" :readonly="true"></b-form-input>
                                          </span>
                                      </div>
                                   </b-col>
                                   <b-col sm="4">
                                      <div class="form-group">
                                          <label for="input-small" >Buy Now Daily Limit</label>
                                          <span v-if="def">
                                          <b-form-input id="input-small" v-model="value.default.send.sbndl"  :readonly="true"></b-form-input>
                                          </span>
                                          <span v-else>
                                          <b-form-input id="input-small" v-model="value.limits.send.sbndl"  :readonly="true"></b-form-input>
                                          </span>
                                      </div>
                                   </b-col>
                                   <b-col sm="4">
                                      <div class="form-group">
                                          <label for="input-small" >Wallet Limit :</label>
                                          <span v-if="def">
                                          <b-form-input id="input-small" v-model="value.default.send.swl" :readonly="true"></b-form-input>
                                          </span>
                                          <span v-else>
                                          <b-form-input id="input-small" v-model="value.limits.send.swl" :readonly="true"></b-form-input>
                                          </span>                                          
                                      </div>
                                   </b-col>
                                </b-row>
                                <b-row>
                                   <b-col sm="4">
                                      <div class="form-group">
                                          <label for="input-small" >Topup Annual Limit :</label>
                                          <span v-if="def">
                                          <b-form-input id="input-small" v-model="value.default.send.stual" :readonly="true"></b-form-input>
                                          </span>
                                          <span v-else>
                                          <b-form-input id="input-small" v-model="value.limits.send.stual" :readonly="true"></b-form-input>
                                          </span>                                          
                                      </div>
                                   </b-col>
                                   <b-col sm="4">
                                      <div class="form-group">
                                          <label for="input-small" >Buy Now Annual Limit :</label>
                                          <span v-if="def">
                                          <b-form-input id="input-small" v-model="value.default.send.sbnal" :readonly="true"></b-form-input>
                                          </span>
                                          <span v-else>
                                          <b-form-input id="input-small" v-model="value.limits.send.sbnal" :readonly="true"></b-form-input>
                                          </span>
                                      </div>
                                   </b-col>                                 
                                </b-row>
                                <b-row>
                                   <b-col sm="4">
                                      <div class="form-group">
                                          <label for="input-small" >Pay Now Daily Limit :</label>  
                                          <span v-if="def">
                                          <b-form-input id="input-small" v-model="value.default.send.spndl" :readonly="true"></b-form-input>
                                          </span>
                                          <span v-else>
                                          <b-form-input id="input-small" v-model="value.limits.send.spndl" :readonly="true"></b-form-input>
                                          </span>
                                      </div>
                                   </b-col>
                                   <b-col sm="4">
                                      <div class="form-group">
                                          <label for="input-small" >Remit Daily Limit :</label>
                                          <span v-if="def">
                                          <b-form-input id="input-small" v-model="value.default.send.srdl" :readonly="true"></b-form-input>
                                          </span>
                                          <span v-else>
                                          <b-form-input id="input-small" v-model="value.limits.send.srdl" :readonly="true"></b-form-input>
                                          </span>
                                      </div>
                                   </b-col>                                 
                                </b-row>
                                <b-row>
                                   <b-col sm="4">
                                      <div class="form-group">
                                          <label for="input-small" >Pay Now Annual Limit :</label>
                                          <span v-if="def">
                                          <b-form-input id="input-small" v-model="value.default.send.spnal" :readonly="true"></b-form-input>
                                          </span>
                                          <span v-else>
                                          <b-form-input id="input-small" v-model="value.limits.send.spnal" :readonly="true"></b-form-input>
                                          </span>                                          
                                      </div>
                                   </b-col>
                                   <b-col sm="4">
                                      <div class="form-group">
                                          <label for="input-small" >Remit Annual Limit :</label>
                                          <span v-if="def">
                                          <b-form-input id="input-small" v-model="value.default.send.sral"  :readonly="true"></b-form-input>
                                          </span>
                                          <span v-else>
                                          <b-form-input id="input-small" v-model="value.limits.send.sral"  :readonly="true"></b-form-input>
                                          </span>
                                      </div>
                                   </b-col>                                 
                                </b-row>
                </CTab>

                <CTab title="Receive"><br/>
                              <b-row>
                                     <b-col sm="4">
                                        <div class="form-group">
                                            <label for="input-small" >Topup Daily Limit :</label>
                                            <span v-if="def">
                                            <b-form-input id="input-small" v-model="value.default.receive.rtudl" :readonly="true"></b-form-input>
                                            </span>
                                            <span v-else>
                                            <b-form-input id="input-small" v-model="value.limits.receive.rtudl" :readonly="true"></b-form-input>
                                            </span>                                            
                                        </div>
                                     </b-col>
                                     <b-col sm="4">
                                        <div class="form-group">
                                            <label for="input-small" >Buy Now Daily Limit</label>
                                            <span v-if="def">
                                            <b-form-input id="input-small" v-model="value.default.receive.rbndl" :readonly="true"></b-form-input>
                                            </span>
                                            <span v-else>
                                            <b-form-input id="input-small" v-model="value.limits.receive.rbndl" :readonly="true"></b-form-input>
                                            </span>                                            
                                        </div>
                                     </b-col>
                                     <b-col sm="4">
                                        <div class="form-group">
                                            <label for="input-small" >Wallet Limit :</label>
                                            <span v-if="def">
                                            <b-form-input id="input-small" v-model="value.default.receive.rwl"  :readonly="true"></b-form-input>
                                            </span>
                                            <span v-else>
                                            <b-form-input id="input-small" v-model="value.limits.receive.rwl"  :readonly="true"></b-form-input>
                                            </span>                                            
                                        </div>
                                     </b-col>
                                  </b-row>

                                  <b-row>
                                     <b-col sm="4">
                                        <div class="form-group">
                                            <label for="input-small" >Topup Annual Limit :</label>
                                            <span v-if="def">
                                            <b-form-input id="input-small" v-model="value.default.receive.rtual" :readonly="true"></b-form-input>
                                            </span>
                                            <span v-else>
                                            <b-form-input id="input-small" v-model="value.limits.receive.rtual" :readonly="true"></b-form-input>
                                            </span>                                            
                                        </div>
                                     </b-col>
                                     <b-col sm="4">
                                        <div class="form-group">
                                            <label for="input-small" >Buy Now Annual Limit :</label>
                                            <span v-if="def">
                                            <b-form-input id="input-small" v-model="value.default.receive.rbnal" :readonly="true"></b-form-input>
                                            </span>
                                            <span v-else>
                                            <b-form-input id="input-small" v-model="value.limits.receive.rbnal" :readonly="true"></b-form-input>
                                            </span>                                            
                                        </div>
                                     </b-col>                                 
                                  </b-row>

                                   <b-row>
                                     <b-col sm="4">
                                        <div class="form-group">
                                            <label for="input-small" >Pay Now Daily Limit :</label>
                                            <span v-if="def">
                                            <b-form-input id="input-small" v-model="value.default.receive.rpndl" :readonly="true"></b-form-input>
                                            </span>
                                            <span v-else>
                                            <b-form-input id="input-small" v-model="value.limits.receive.rpndl" :readonly="true"></b-form-input>
                                            </span>
                                        </div>
                                     </b-col>
                                     <b-col sm="4">
                                        <div class="form-group">
                                            <label for="input-small" >Remit Daily Limit :</label>
                                            <span v-if="def">
                                            <b-form-input id="input-small" v-model="value.default.receive.rrdl" :readonly="true"></b-form-input>
                                            </span>
                                            <span v-else>
                                            <b-form-input id="input-small" v-model="value.limits.receive.rrdl" :readonly="true"></b-form-input>
                                            </span>
                                        </div>
                                     </b-col>                                 
                                  </b-row>

                                  <b-row>
                                     <b-col sm="4">
                                        <div class="form-group">
                                            <label for="input-small" >Pay Now Annual Limit :</label>
                                            <span v-if="def">
                                            <b-form-input id="input-small" v-model="value.default.receive.rpnal" :readonly="true"></b-form-input>
                                            </span>
                                            <span v-else>
                                            <b-form-input id="input-small" v-model="value.limits.receive.rpnal" :readonly="true"></b-form-input>
                                            </span>                                            
                                        </div>
                                     </b-col>
                                     <b-col sm="4">
                                        <div class="form-group">
                                            <label for="input-small" >Remit Annual Limit :</label>
                                            <span v-if="def">
                                            <b-form-input id="input-small" v-model="value.default.receive.rral" :readonly="true"></b-form-input>
                                            </span>
                                            <span v-else>
                                            <b-form-input id="input-small" v-model="value.limits.receive.rral" :readonly="true"></b-form-input>
                                            </span>                                            
                                        </div>
                                     </b-col>                                 
                                  </b-row>                
                </CTab>           
            </CTabs>                   

          </b-card-body>
        </b-collapse>
      </b-card>     
    </div>
    </div>
  </div>
</template>

<script>
import AuthService from '@/api/AuthService.js';

export default {
    name: 'wallet',    
    data() {
        return {           
            myToggle: false,
            buttons: [],
            wallets:[],
            walletName:'',                
            limits:[],
            limitt:[],
            def: false,
            sbl : "*",
        };
      },
    directives: {       
    },
    created () {  
       this.getUserWalletGroup(this.$route.params.id);
    },   
    methods: {
      actionChange(key,idx){  //console.log(key +'='+ idx);
        //console.log(this.wallets[key][idx]);
        var row = this.wallets[key];
        for (var key2 in row) {
            if(key2==idx){                
                this.wallets[key][key2].show = true;
            } else {                
                this.wallets[key][key2].show = false;
            }
        }
      },

      uniqueString: function(length) {
            var result           = '';
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
              result += characters.charAt(Math.floor(Math.random() * charactersLength));
           }
           return result;
      },

      getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key] === value);
      },

      setLimitValue(trlimits,key5,curr){

                                        var studl = 0;
                                        if (trlimits['topupCurrencyLimits'][key5] && (key5==curr)) {
                                            studl = trlimits['topupCurrencyLimits'][key5]['dailyLimit']['sendLimit'];
                                        } else if(key5==curr) {
                                            studl = trlimits['defaultCurrencyLimits'][key5]['dailyLimit']['sendLimit'];
                                        }  

                                        var stual = 0;
                                        if (trlimits['topupCurrencyLimits'][key5] && (key5==curr)) {
                                            stual = trlimits['topupCurrencyLimits'][key5]['annualLimit']['sendLimit'];
                                        } else if(key5==curr) {
                                            stual = trlimits['defaultCurrencyLimits'][key5]['annualLimit']['sendLimit'];
                                        }
                                            
                                        var spndl=0;
                                        if (trlimits['p2pCurrencyLimits'][key5] && (key5==curr)) {
                                            spndl = trlimits['p2pCurrencyLimits'][key5]['dailyLimit']['sendLimit'];
                                        } else if(key5==curr) {
                                            spndl = trlimits['defaultCurrencyLimits'][key5]['dailyLimit']['sendLimit'];
                                        } 

                                        var spnal=0;
                                        if (trlimits['p2pCurrencyLimits'][key5] && (key5==curr)) {
                                            spnal = trlimits['p2pCurrencyLimits'][key5]['annualLimit']['sendLimit'];
                                        } else if(key5==curr) {
                                            spnal = trlimits['defaultCurrencyLimits'][key5]['annualLimit']['sendLimit'];
                                        } 

                                        var sbndl=0;
                                        if (trlimits['p2mCurrencyLimits'][key5] && (key5==curr)) {
                                            sbndl = trlimits['p2mCurrencyLimits'][key5]['dailyLimit']['sendLimit'];
                                        } else if(key5==curr) {
                                            sbndl = trlimits['defaultCurrencyLimits'][key5]['dailyLimit']['sendLimit'];
                                        }

                                        var sbnal=0;
                                        if (trlimits['p2mCurrencyLimits'][key5] && (key5==curr)) {
                                            sbnal = trlimits['p2mCurrencyLimits'][key5]['annualLimit']['sendLimit'];
                                        } else if(key5==curr) {
                                            sbnal = trlimits['defaultCurrencyLimits'][key5]['annualLimit']['sendLimit'];
                                        } 

                                        var srdl=0;
                                        if (trlimits['remittanceCurrencyLimits'][key5] && (key5==curr)) {
                                            srdl = trlimits['remittanceCurrencyLimits'][key5]['dailyLimit']['sendLimit'];
                                        } else if(key5==curr) {
                                            srdl = trlimits['defaultCurrencyLimits'][key5]['dailyLimit']['sendLimit'];
                                        }

                                        var sral=0;
                                        if (trlimits['remittanceCurrencyLimits'][key5] && (key5==curr)) {
                                            sral = trlimits['remittanceCurrencyLimits'][key5]['annualLimit']['sendLimit'];
                                        } else if(key5==curr) {
                                            sral = trlimits['defaultCurrencyLimits'][key5]['annualLimit']['sendLimit'];
                                        }

                                        var swl=0; 

                                        //============== 

                                        var rtudl = 0;
                                        if (trlimits['topupCurrencyLimits'][key5] && (key5==curr)) {
                                            rtudl = trlimits['topupCurrencyLimits'][key5]['dailyLimit']['receiveLimit'];
                                        } else if(key5==curr) {
                                            rtudl = trlimits['defaultCurrencyLimits'][key5]['dailyLimit']['receiveLimit'];
                                        }   

                                        var rtual = 0;
                                        if (trlimits['topupCurrencyLimits'][key5] && (key5==curr)) {
                                            rtual = trlimits['topupCurrencyLimits'][key5]['annualLimit']['receiveLimit'];
                                        } else if(key5==curr) {
                                            rtual = trlimits['defaultCurrencyLimits'][key5]['annualLimit']['receiveLimit'];
                                        }    
                                            
                                        var rpndl=0;
                                        if (trlimits['p2pCurrencyLimits'][key5] && (key5==curr)) {
                                            rpndl = trlimits['p2pCurrencyLimits'][key5]['dailyLimit']['receiveLimit'];
                                        } else if(key5==curr) {
                                            rpndl = trlimits['defaultCurrencyLimits'][key5]['dailyLimit']['receiveLimit'];
                                        }   

                                        var rpnal=0;
                                        if (trlimits['p2pCurrencyLimits'][key5] && (key5==curr)) {
                                            rpnal = trlimits['p2pCurrencyLimits'][key5]['annualLimit']['receiveLimit'];
                                        } else if(key5==curr) {
                                            rpnal = trlimits['defaultCurrencyLimits'][key5]['annualLimit']['receiveLimit'];
                                        }    

                                        var rbndl=0;
                                        if (trlimits['p2mCurrencyLimits'][key5] && (key5==curr)) {
                                            rbndl = trlimits['p2mCurrencyLimits'][key5]['dailyLimit']['receiveLimit'];
                                        } else if(key5==curr) {
                                            rbndl = trlimits['defaultCurrencyLimits'][key5]['dailyLimit']['receiveLimit'];
                                        }    

                                        var rbnal=0;
                                        if (trlimits['p2mCurrencyLimits'][key5] && (key5==curr)) {
                                            rbnal = trlimits['p2mCurrencyLimits'][key5]['annualLimit']['receiveLimit'];
                                        } else if(key5==curr) {
                                            rbnal = trlimits['defaultCurrencyLimits'][key5]['annualLimit']['receiveLimit'];
                                        }   

                                        var rrdl=0;
                                        if (trlimits['remittanceCurrencyLimits'][key5] && (key5==curr)) {
                                            rrdl = trlimits['remittanceCurrencyLimits'][key5]['dailyLimit']['receiveLimit'];
                                        } else if(key5==curr) {
                                            rrdl = trlimits['defaultCurrencyLimits'][key5]['dailyLimit']['receiveLimit'];
                                        }   

                                        var rral=0;
                                        if (trlimits['remittanceCurrencyLimits'][key5] && (key5==curr)) {
                                            rral = trlimits['remittanceCurrencyLimits'][key5]['annualLimit']['receiveLimit'];
                                        } else if(key5==curr) {
                                            rral = trlimits['defaultCurrencyLimits'][key5]['annualLimit']['receiveLimit'];
                                        }   

                                        var rwl=0; 
                                        
            var data = {
                        /* Send */
                        studl : studl,
                        stual : stual,
                        spndl : spndl,
                        spnal : spnal,
                        sbndl : sbndl,
                        sbnal : sbnal,
                        srdl  : srdl,
                        sral  : sral,
                        swl   : swl,
                        /* Receive */
                        rtudl : rtudl,
                        rtual : rtual,
                        rpndl : rpndl,
                        rpnal : rpnal,
                        rbndl : rbndl,
                        rbnal : rbnal,
                        rrdl  : rrdl,
                        rral  : rral,
                        rwl   : rwl  
                    };

            return data;  
      },

    //   setStdLimitValue(deftrlimits,key8,curr){

    //                                     var dstudl = 0;
    //                                     if (deftrlimits['topupCurrencyLimits'][key8] && (key8==curr)) {
    //                                         dstudl = deftrlimits['topupCurrencyLimits'][key8]['dailyLimit']['sendLimit'];
    //                                     } else if(key8==curr) {
    //                                         dstudl = deftrlimits['defaultCurrencyLimits'][key8]['dailyLimit']['sendLimit'];
    //                                     }  

    //                                     var dstual = 0;
    //                                     if (deftrlimits['topupCurrencyLimits'][key8] && (key8==curr)) {
    //                                          dstual = deftrlimits['topupCurrencyLimits'][key8]['annualLimit']['sendLimit'];
    //                                     } else if(key8==curr) {
    //                                          dstual = deftrlimits['defaultCurrencyLimits'][key8]['annualLimit']['sendLimit'];
    //                                     } 
                                             
    //                                     var dspndl=0;
    //                                     if (deftrlimits['p2pCurrencyLimits'][key8] && (key8==curr)) {
    //                                         dspndl = deftrlimits['p2pCurrencyLimits'][key8]['dailyLimit']['sendLimit'];
    //                                     } else if(key8==curr){
    //                                         dspndl = deftrlimits['defaultCurrencyLimits'][key8]['dailyLimit']['sendLimit'];
    //                                     }

    //                                     var dspnal=0;
    //                                     if (deftrlimits['p2pCurrencyLimits'][key8] && (key8==curr)) {
    //                                         dspnal = deftrlimits['p2pCurrencyLimits'][key8]['annualLimit']['sendLimit'];
    //                                     } else if(key8==curr) {
    //                                         dspnal = deftrlimits['defaultCurrencyLimits'][key8]['annualLimit']['sendLimit'];
    //                                     } 

    //                                     var dsbndl=0;
    //                                     if (deftrlimits['p2mCurrencyLimits'][key8] && (key8==curr)) {
    //                                         dsbndl = deftrlimits['p2mCurrencyLimits'][key8]['dailyLimit']['sendLimit'];
    //                                     } else if(key8==curr){
    //                                         dsbndl = deftrlimits['defaultCurrencyLimits'][key8]['dailyLimit']['sendLimit'];
    //                                     }

    //                                     var dsbnal=0;
    //                                     if (deftrlimits['p2mCurrencyLimits'][key8] && (key8==curr)) {
    //                                          dsbnal = deftrlimits['p2mCurrencyLimits'][key8]['annualLimit']['sendLimit'];
    //                                     } else if(key8==curr){
    //                                          dsbnal = deftrlimits['defaultCurrencyLimits'][key8]['annualLimit']['sendLimit'];
    //                                     }

    //                                     var dsrdl=0;
    //                                     if (deftrlimits['remittanceCurrencyLimits'][key8] && (key8==curr)) {
    //                                         dsrdl = deftrlimits['remittanceCurrencyLimits'][key8]['dailyLimit']['sendLimit'];
    //                                     } else if(key8==curr) {
    //                                         dsrdl = deftrlimits['defaultCurrencyLimits'][key8]['dailyLimit']['sendLimit'];
    //                                     }

    //                                     var dsral=0;
    //                                     if (deftrlimits['remittanceCurrencyLimits'][key8] && (key8==curr)) {
    //                                          dsral = deftrlimits['remittanceCurrencyLimits'][key8]['annualLimit']['sendLimit'];
    //                                     } else if(key8==curr) {
    //                                          dsral = deftrlimits['defaultCurrencyLimits'][key8]['annualLimit']['sendLimit'];
    //                                     } 
    //                                     var dswl=0; 
    //                                     //===========

    //                                     var drtudl = 0;
    //                                     if (deftrlimits['topupCurrencyLimits'][key8] && (key8==curr)) {
    //                                          drtudl = deftrlimits['topupCurrencyLimits'][key8]['dailyLimit']['receiveLimit'];
    //                                     } else if(key8==curr) {
    //                                          drtudl = deftrlimits['defaultCurrencyLimits'][key8]['dailyLimit']['receiveLimit'];
    //                                     }  

    //                                     var drtual = 0;
    //                                     if (deftrlimits['topupCurrencyLimits'][key8] && (key8==curr)) {
    //                                          drtual = deftrlimits['topupCurrencyLimits'][key8]['annualLimit']['receiveLimit'];
    //                                     } else if(key8==curr) {
    //                                          drtual = deftrlimits['defaultCurrencyLimits'][key8]['annualLimit']['receiveLimit'];
    //                                     }
                                             
    //                                     var drpndl=0;
    //                                     if (deftrlimits['p2pCurrencyLimits'][key8] && (key8==curr)) {
    //                                          drpndl = deftrlimits['p2pCurrencyLimits'][key8]['dailyLimit']['receiveLimit'];
    //                                     } else if(key8==curr) {
    //                                          drpndl = deftrlimits['defaultCurrencyLimits'][key8]['dailyLimit']['receiveLimit'];
    //                                     }

    //                                     var drpnal=0;
    //                                     if (deftrlimits['p2pCurrencyLimits'][key8] && (key8==curr)) {
    //                                          drpnal = deftrlimits['p2pCurrencyLimits'][key8]['annualLimit']['receiveLimit'];
    //                                     } else if(key8==curr) {
    //                                          drpnal = deftrlimits['defaultCurrencyLimits'][key8]['annualLimit']['receiveLimit'];
    //                                     } 

    //                                     var drbndl=0;
    //                                     if (deftrlimits['p2mCurrencyLimits'][key8] && (key8==curr)) {
    //                                          drbndl = deftrlimits['p2mCurrencyLimits'][key8]['dailyLimit']['receiveLimit'];
    //                                     } else if(key8==curr) {
    //                                          drbndl = deftrlimits['defaultCurrencyLimits'][key8]['dailyLimit']['receiveLimit'];
    //                                     }

    //                                     var drbnal=0;
    //                                     if (deftrlimits['p2mCurrencyLimits'][key8] && (key8==curr)) {
    //                                          drbnal = deftrlimits['p2mCurrencyLimits'][key8]['annualLimit']['receiveLimit'];
    //                                     } else if(key8==curr) {
    //                                          drbnal = deftrlimits['defaultCurrencyLimits'][key8]['annualLimit']['receiveLimit'];
    //                                     }

    //                                     var drrdl=0;
    //                                     if (deftrlimits['remittanceCurrencyLimits'][key8] && (key8==curr)) {
    //                                          drrdl = deftrlimits['remittanceCurrencyLimits'][key8]['dailyLimit']['receiveLimit'];
    //                                     } else if(key8==curr) {
    //                                          drrdl = deftrlimits['defaultCurrencyLimits'][key8]['dailyLimit']['receiveLimit'];
    //                                     }

    //                                     var drral=0;
    //                                     if (deftrlimits['remittanceCurrencyLimits'][key8] && (key8==curr)) {
    //                                          drral = deftrlimits['remittanceCurrencyLimits'][key8]['annualLimit']['receiveLimit'];
    //                                     } else if(key8==curr) {
    //                                          drral = deftrlimits['defaultCurrencyLimits'][key8]['annualLimit']['receiveLimit'];
    //                                     }
    //                                     var drwl=0;                             
                                        
    //         var data = {
    //                     /* Send */
    //                     studl : dstudl,
    //                     stual : dstual,
    //                     spndl : dspndl,
    //                     spnal : dspnal,
    //                     sbndl : dsbndl,
    //                     sbnal : dsbnal,
    //                     srdl  : dsrdl,
    //                     sral  : dsral,
    //                     swl   : dswl,
    //                     /* Receive */
    //                     rtudl : drtudl,
    //                     rtual : drtual,
    //                     rpndl : drpndl,
    //                     rpnal : drpnal,
    //                     rbndl : drbndl,
    //                     rbnal : drbnal,
    //                     rrdl  : drrdl,
    //                     rral  : drral,
    //                     rwl   : drwl     
                        
    //                 };            

    //         return data;  
    //   },

      getUserWalletGroup: function(id) {
          AuthService.getUserDetail(id).then(resp => {  //console.log(resp.wallets);
           
            var preferredWalletId = resp.preferredWalletId;          
          
            var limitsPerCurr=[];  var SGDlimitsPerCurr=[]; 
            var j =1;   var i = 1;    
            /* Iterate each user wallet */
            for (var key in resp.wallets) {            

                //var color    = (preferredWalletId  === resp.wallets[key].walletId) ? "primary" : "success";  
                var name     = resp.wallets[key].walletGroup.name;  //console.log(name);
                var limits   = resp.wallets[key].walletGroup.limits; //console.log(limits); 
                var primaryStoreRef = resp.wallets[key].primaryStoreRef; //console.log(primaryStoreRef); 
                var accounts = resp.wallets[key].accounts; 
                var codegroup    = Object.keys(limits); var jum = codegroup.length;
            
                /* Iterate each user account */
                for (var key2 in accounts) {   //console.log(accounts[key2]);                    
                    var curr     = accounts[key2].currency; //console.log(j +'|'+ curr);
                    var storeRef = accounts[key2].storeRef;  
                    var color    = (storeRef  === primaryStoreRef ) ? "#80ff00" : "#59b300"; 
                    var currency = []; var code = [];  var group = []; 
                    var history  = []; var code2 = [];  var group2 = [];  var k=1;  var j=1;
                    
                    if(name == "sg-standard-wallet-group"){ 
                        var defaultLimits = resp.defaultUserGroup.limits; //console.log(defaultLimits); 
                        var codegroup2 = Object.keys(defaultLimits); var jum2 = codegroup2.length;

                        for (var key6 in defaultLimits) {  
                            var deftrlimits = defaultLimits[key6].transactionLimits; 
                            for (var key7 in deftrlimits) { 
                                for (var key8 in deftrlimits[key7]) { 
                                        var val = this.setLimitValue(deftrlimits,key8,curr);                                                                             
                                        //console.log(history);  console.log(code2);
                                        if(!history.includes(curr) || !code2.includes(key6)) {
                                            //console.log('SG STD INSERT :' + curr +' sub  : '+ key6); 
                                            //console.log('----------------------');
                                            code2.push(key6);                                          
                                            history.push(curr); 

                                            var show = (j==1) ? true : false;
                                            j++;

                                            var lim = {  
                                                show : show,
                                                tab : key6,
                                                button : codegroup2,                                          
                                                group : name , 
                                                color : color,
                                                currency : accounts[key2].currency, 
                                                storeRef : storeRef,
                                                balance  : accounts[key2].amount, 
                                                uniqueStr : this.uniqueString(5),                                            
                                                limits : {                                                     
                                                    send : {
                                                            studl : val.studl,
                                                            stual : val.stual,
                                                            spndl : val.spndl,
                                                            spnal : val.spnal,
                                                            sbndl : val.sbndl,
                                                            sbnal : val.sbnal,
                                                            srdl  : val.srdl,
                                                            sral  : val.sral,
                                                            swl   : val.swl
                                                    },
                                                    receive:{
                                                            rtudl : val.rtudl,
                                                            rtual : val.rtual,
                                                            rpndl : val.rpndl,
                                                            rpnal : val.rpnal,
                                                            rbndl : val.rbndl,
                                                            rbnal : val.rbnal,
                                                            rrdl  : val.rrdl,
                                                            rral  : val.rral,
                                                            rwl   : val.rwl                                              
                                                    } 
                                                    }
                                                }; 
                                       
                                            group2.push(lim);                                          
                                            if(code2.length === jum2 ){  
                                                if(curr==="SGD"){
                                                    SGDlimitsPerCurr.push(group2);
                                                } else {
                                                    limitsPerCurr.push(group2);  
                                                }                                             
                                                                                               
                                            }        
                                        
                                        }  
                                }  
                            }                            
                        }   
                    } else { //if(name == "test-group-3") {    
                        for (var key3 in limits) {   
                            var trlimits = limits[key3].transactionLimits;  
                            
                            for (var key4 in trlimits) {  
                                for (var key5 in trlimits[key4]) {  
                                        var val = this.setLimitValue(trlimits,key5,curr);                                         
                                        if(!currency.includes(curr) || !code.includes(key3)){  
                                            //console.log('INSERT :' +name +' = '+curr);   
                                            //console.log('------------------');                                    
                                            currency.push(curr);
                                            code.push(key3);

                                            var show = (k==1) ? true : false;
                                            k++;
                                                                                    
                                            var lim = {         show : show,
                                                                tab : key3,
                                                                button : codegroup,
                                                                group : name , 
                                                                color : color,
                                                                currency : accounts[key2].currency, 
                                                                storeRef : storeRef,
                                                                balance  : accounts[key2].amount,
                                                                uniqueStr : this.uniqueString(5), 
                                                                limits : { 
                                                                    send : {
                                                                            studl : val.studl,
                                                                            stual : val.stual,
                                                                            spndl : val.spndl,
                                                                            spnal : val.spnal,
                                                                            sbndl : val.sbndl,
                                                                            sbnal : val.sbnal,
                                                                            srdl  : val.srdl,
                                                                            sral  : val.sral,
                                                                            swl   : val.swl
                                                                    },
                                                                    receive:{
                                                                            rtudl : val.rtudl,
                                                                            rtual : val.rtual,
                                                                            rpndl : val.rpndl,
                                                                            rpnal : val.rpnal,
                                                                            rbndl : val.rbndl,
                                                                            rbnal : val.rbnal,
                                                                            rrdl  : val.rrdl,
                                                                            rral  : val.rral,
                                                                            rwl   : val.rwl                                              
                                                                    } 
                                                                }                                                             
                                            }; 
                                            // var count = Object.keys(lim).length;
                                            // console.log(count);
                                            group.push(lim);                                          
                                            if(code.length === jum ){  
                                                if(curr==="SGD"){
                                                    SGDlimitsPerCurr.push(group);
                                                } else {                                               
                                                    limitsPerCurr.push(group);  
                                                }                                               
                                            }                                          
                                            
                                        }                              
                                    
                                } 
                            } 
                        } 
                    }
                j++; }
            } 

            /* Move on the top for SGD tab */
            const joined = SGDlimitsPerCurr.concat(limitsPerCurr);            
            this.wallets = joined;  
            // const indexOfAll = (arr, val) => arr.reduce((acc, el, i) => (el === val ? [...acc, i] : acc), []);
            // var indexCurr = indexOfAll(currency, "SGD"); 
            // var k = 0;

            // let temp={};           
            // for (const val of indexCurr) {                 
            //     temp = limitsPerCurr[k];
            //     limitsPerCurr[k] = limitsPerCurr[val];
            //     limitsPerCurr[val] = temp;  
            //     temp.length = 0;
            // k++ }

          }, error => {
            console.log('error');    
          });
      }

    },
 };

</script>